import React from 'react'
import { BrowserRouter, Route, Routes as Switch } from 'react-router-dom'
import Login from './pages/Login/Login'

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <SwitchRoute />
    </BrowserRouter>
  )
}

const SwitchRoute: React.FC = () => {
  return (
    <Switch>
      <Route path="/" element={<Login />} />
    </Switch>
  )
}
export default App
