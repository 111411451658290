import React from 'react'
import { Button, Container, TextField, Typography } from '@mui/material'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

interface initialFormValues{
    username: string;
    password: string;
}

export const Login: React.FC = () => {
  const initialFormValues: initialFormValues  = {
    username: "",
    password: "",
  }
  const handleSubmit = async (
    values: { username: string; password: string },
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    console.log(values)
  }

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  })
  return (
    <Container maxWidth="xs">
      <Typography variant="h4" align="center" gutterBottom>
        Login
      </Typography>
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field
              as={TextField}
              fullWidth
              name="username"
              label="Username"
              variant="outlined"
              margin="normal"
              helperText={<ErrorMessage name="username" />}
            />
            <Field
              as={TextField}
              fullWidth
              type="password"
              name="password"
              label="Password"
              variant="outlined"
              margin="normal"
              helperText={<ErrorMessage name="password" />}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isSubmitting}
            >
              Login
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default Login
